/* tslint:disable */
/* eslint-disable */
/**
 * Finder API
 * Finder IF定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * カテゴリ名
     * @type {Array<string>}
     * @memberof Category
     */
    items: Array<string>;
    /**
     * 選択中カテゴリ。配列だけど現在一つだけ可能
     * @type {Array<string>}
     * @memberof Category
     */
    selected: Array<string>;
}
/**
 * ドキュメント種別
 * @export
 * @enum {string}
 */

export enum Doctype {
    All = 'all',
    Html = 'html',
    Pdf = 'pdf',
    Doc = 'doc',
    Xls = 'xls',
    Ppt = 'ppt'
}

/**
 * ドリルダウンの階層情報
 * @export
 * @interface FacetDrillDownInfo
 */
export interface FacetDrillDownInfo {
    /**
     * ドリルダウン階層の深さ（最上位階層は1）
     * @type {number}
     * @memberof FacetDrillDownInfo
     */
    depth: number;
    /**
     * 階層の表示テキスト
     * @type {string}
     * @memberof FacetDrillDownInfo
     */
    title: string;
    /**
     * ドリルダウンラベルを最上位から順にタブ区切りで連結した文字列
     * @type {string}
     * @memberof FacetDrillDownInfo
     */
    path: string;
    /**
     * この階層内で検索にヒットしたページ数
     * @type {number}
     * @memberof FacetDrillDownInfo
     */
    count: number;
    /**
     * この階層で絞り込まれている場合にtrueがセットされる
     * @type {boolean}
     * @memberof FacetDrillDownInfo
     */
    is_selected: boolean;
    /**
     * この階層もしくはこのカテゴリの下位カテゴリで絞り込まれている場合にtrueがセットされる
     * @type {boolean}
     * @memberof FacetDrillDownInfo
     */
    is_opened: boolean;
    /**
     * 下位ラベル情報のリスト
     * @type {Array<FacetDrillDownInfo>}
     * @memberof FacetDrillDownInfo
     */
    children?: Array<FacetDrillDownInfo>;
}
/**
 * ドリルダウンの階層情報
 * @export
 * @interface FacetDrillDownRootInfo
 */
export interface FacetDrillDownRootInfo {
    /**
     * このカテゴリrootで絞り込まれている場合にtrueがセットされる
     * @type {boolean}
     * @memberof FacetDrillDownRootInfo
     */
    is_selected: boolean;
    /**
     * このカテゴリrootもしくはこのカテゴリの下位カテゴリで絞り込まれている場合にtrueがセットされる
     * @type {boolean}
     * @memberof FacetDrillDownRootInfo
     */
    is_opened: boolean;
    /**
     * そのドリルダウンrootに属するドリルダウン情報
     * @type {Array<FacetDrillDownInfo>}
     * @memberof FacetDrillDownRootInfo
     */
    facet_drilldowns: Array<FacetDrillDownInfo>;
}
/**
 * 
 * @export
 * @interface FeaturedContents
 */
export interface FeaturedContents {
    /**
     * 
     * @type {Array<FeaturedContentsDoc>}
     * @memberof FeaturedContents
     */
    docs: Array<FeaturedContentsDoc>;
}
/**
 * 
 * @export
 * @interface FeaturedContentsDoc
 */
export interface FeaturedContentsDoc {
    /**
     * 
     * @type {string}
     * @memberof FeaturedContentsDoc
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof FeaturedContentsDoc
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof FeaturedContentsDoc
     */
    thumbnail_url?: string;
}
/**
 * 
 * @export
 * @interface GetHealthCheckResponse
 */
export interface GetHealthCheckResponse {
    /**
     * 
     * @type {string}
     * @memberof GetHealthCheckResponse
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealthCheckResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface GetRelatedKeywordsResponse
 */
export interface GetRelatedKeywordsResponse {
    /**
     * 
     * @type {Array<RelatedKeyword>}
     * @memberof GetRelatedKeywordsResponse
     */
    related_keywords: Array<RelatedKeyword>;
}
/**
 * ページパーツ毎のハイライト情報
 * @export
 * @interface HighlightInfo
 */
export interface HighlightInfo {
    /**
     * urlのハイライトパーツ
     * @type {Array<HighlightPart>}
     * @memberof HighlightInfo
     */
    url_parts: Array<HighlightPart>;
    /**
     * titleのハイライトパーツ
     * @type {Array<HighlightPart>}
     * @memberof HighlightInfo
     */
    title_parts: Array<HighlightPart>;
    /**
     * descriptionのハイライトパーツ
     * @type {Array<HighlightPart>}
     * @memberof HighlightInfo
     */
    description_parts: Array<HighlightPart>;
    /**
     * document textののハイライトパーツ
     * @type {Array<HighlightPart>}
     * @memberof HighlightInfo
     */
    document_text_parts: Array<HighlightPart>;
}
/**
 * テキストのハイライト情報
 * @export
 * @interface HighlightPart
 */
export interface HighlightPart {
    /**
     * ハイライト有無 有り：true/無し：false 
     * @type {boolean}
     * @memberof HighlightPart
     */
    is_highlighted: boolean;
    /**
     * テキスト文字列
     * @type {string}
     * @memberof HighlightPart
     */
    text: string;
}
/**
 * 検索結果のページ毎の情報
 * @export
 * @interface OrganicDoc
 */
export interface OrganicDoc {
    /**
     * 検索結果順位
     * @type {number}
     * @memberof OrganicDoc
     */
    ranking: number;
    /**
     * ドキュメントURL（クロールURL）
     * @type {string}
     * @memberof OrganicDoc
     */
    url: string;
    /**
     * ページタイトル
     * @type {string}
     * @memberof OrganicDoc
     */
    title: string;
    /**
     * ページdescrition
     * @type {string}
     * @memberof OrganicDoc
     */
    description: string;
    /**
     * ページ本文先頭n文字 （PDF等の巨大文字列コンテンツ対応）
     * @type {string}
     * @memberof OrganicDoc
     */
    start_text: string;
    /**
     * 
     * @type {HighlightInfo}
     * @memberof OrganicDoc
     */
    highlight: HighlightInfo;
    /**
     * コンテンツサイズ
     * @type {number}
     * @memberof OrganicDoc
     */
    content_size: number;
    /**
     * コンテンツ最終更新時刻(unixtime)
     * @type {number}
     * @memberof OrganicDoc
     */
    last_modified: number;
    /**
     * ドキュメントの所属するドリルダウン
     * @type {Array<string>}
     * @memberof OrganicDoc
     */
    drilldown: Array<string>;
    /**
     * 
     * @type {Doctype}
     * @memberof OrganicDoc
     */
    doctype: Doctype;
    /**
     * サムネイルURL
     * @type {string}
     * @memberof OrganicDoc
     */
    thumbnail_url: string | null;
}
/**
 * 検索結果のページ全体情報
 * @export
 * @interface OrganicDocResult
 */
export interface OrganicDocResult {
    /**
     * ヒットしたページ数
     * @type {number}
     * @memberof OrganicDocResult
     */
    hits: number;
    /**
     * docsに含まれるデータの検索結果順位の最小順位
     * @type {number}
     * @memberof OrganicDocResult
     */
    range_min: number;
    /**
     * docsに含まれるデータの検索結果順位の最大順位
     * @type {number}
     * @memberof OrganicDocResult
     */
    range_max: number;
    /**
     * 検索結果のページ毎情報
     * @type {Array<OrganicDoc>}
     * @memberof OrganicDocResult
     */
    docs: Array<OrganicDoc>;
}
/**
 * 
 * @export
 * @interface Parameter
 */
export interface Parameter {
    /**
     * 全文検索クエリー文字列（searchAPIのクエリパラメータ同様）
     * @type {string}
     * @memberof Parameter
     */
    q: string;
    /**
     * 入力エンコーディング（searchAPIのクエリパラメータ同様）
     * @type {string}
     * @memberof Parameter
     */
    input_encoding: string;
    /**
     * 取得ページ番号 （searchAPIのクエリパラメータ同様）
     * @type {number}
     * @memberof Parameter
     */
    page_number: number;
    /**
     * 1ページ毎の検索結果表示件数（searchAPIのクエリパラメータ同様）
     * @type {number}
     * @memberof Parameter
     */
    number_per_page: number;
    /**
     * ソート順（searchAPIのクエリパラメータ同様）
     * @type {Array<string>}
     * @memberof Parameter
     */
    sort_by: Array<string>;
    /**
     * 全文検索結果を絞り込むカテゴリ
     * @type {string}
     * @memberof Parameter
     */
    category: string;
    /**
     * ドリルダウンにより絞り込み（searchAPIのクエリパラメータ同様）
     * @type {Array<string>}
     * @memberof Parameter
     */
    drilldown: Array<string>;
    /**
     * 出力されるページのドキュメント種類の指定（searchAPIのクエリパラメータ同様）
     * @type {Array<Doctype>}
     * @memberof Parameter
     */
    doctype: Array<Doctype>;
}
/**
 * 
 * @export
 * @interface RelatedKeyword
 */
export interface RelatedKeyword {
    /**
     * 関連キーワード
     * @type {string}
     * @memberof RelatedKeyword
     */
    word: string;
}
/**
 * 全文検索結果
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {Parameter}
     * @memberof SearchResponse
     */
    parameter: Parameter;
    /**
     * 
     * @type {OrganicDocResult}
     * @memberof SearchResponse
     */
    organic: OrganicDocResult;
    /**
     * 
     * @type {Category}
     * @memberof SearchResponse
     */
    category: Category;
    /**
     * 
     * @type {Array<FacetDrillDownRootInfo>}
     * @memberof SearchResponse
     */
    facet_drilldown_roots: Array<FacetDrillDownRootInfo>;
    /**
     * 
     * @type {Spellcheck}
     * @memberof SearchResponse
     */
    spellcheck: Spellcheck;
    /**
     * 
     * @type {FeaturedContents}
     * @memberof SearchResponse
     */
    featured_contents: FeaturedContents;
}
/**
 * もしかして
 * @export
 * @interface Spellcheck
 */
export interface Spellcheck {
    /**
     * 
     * @type {string}
     * @memberof Spellcheck
     */
    main_phrase: string;
}
/**
 * サジェスト結果
 * @export
 * @interface SuggestResponse
 */
export interface SuggestResponse {
    /**
     * 
     * @type {Array<SuggestionInfo>}
     * @memberof SuggestResponse
     */
    suggestions?: Array<SuggestionInfo>;
}
/**
 * １サジェストの情報
 * @export
 * @interface SuggestionInfo
 */
export interface SuggestionInfo {
    /**
     * 候補ワード
     * @type {string}
     * @memberof SuggestionInfo
     */
    word: string;
    /**
     * 候補優先度合。一段サジェストまで存在
     * @type {number}
     * @memberof SuggestionInfo
     */
    point?: number;
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * * 指定された条件の全文検索結果を返す。 * 利用するSolrのエンドポイントは「/select」を利用する。 * FacetDrillDownInfo関連して、solr側のドリルダウン用フィールドの値の持ち方は、階層1<tab>階層2とする。 solr4以降の複数フィールドを組み合わせたpivot facet等の新機能があるが、1ページで1ドリルダウンのルートの複数ドリルダウンに対応できないため。 
         * @summary 全文検索
         * @param {number} contractId * 契約ID
         * @param {number} servicegroupId * サービスグループid
         * @param {number} serviceId * サービスid
         * @param {string} [q] * 全文検索クエリー文字列
         * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
         * @param {number} [pageNumber] * 取得ページ番号(デフォルト:1)
         * @param {number} [numberPerPage] * 1ページ毎の検索結果表示件数 * 値域: 1～100（デフォルト: 10）
         * @param {Array<string>} [sortBy] * ソート順 * フォーマット   * field1 order,field2 order   * 複数項目指定可能   * orderの設定値     * desc:降順（フィールド指定のみの場合、デフォルト）     * asc:昇順 * 利用方法   * マッチ順（降順） -&gt; score desc（デフォルト）   * 新着順（降順） -&gt; last_modified desc   * タイトル辞書順（昇順） -&gt; title asc   * クリック数順（降順） -&gt; click desc
         * @param {string} [category] 全文検索結果を絞り込むカテゴリ
         * @param {Array<string>} [drilldown] * ドリルダウンにより絞り込み * 複数指定可能。 * 複数指定時には A\\&lt;tab&gt;B のようにtab文字(%09)で区切ること * 複数指定時には A OR BのようにOR検索となる。
         * @param {Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>} [doctype] * 出力されるページのドキュメント種類の指定 * カンマ区切りで複数指定可能 * 複数指定時には、OR検索となる * 設定値   * all（デフォルト）   * html   * pdf   * doc   * xls   * ppt 
         * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (contractId: number, servicegroupId: number, serviceId: number, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', pageNumber?: number, numberPerPage?: number, sortBy?: Array<string>, category?: string, drilldown?: Array<string>, doctype?: Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>, callback?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('search', 'contractId', contractId)
            // verify required parameter 'servicegroupId' is not null or undefined
            assertParamExists('search', 'servicegroupId', servicegroupId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('search', 'serviceId', serviceId)
            const localVarPath = `/documents/{contract_id}/{servicegroup_id}/{service_id}/search`
                .replace(`{${"contract_id"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"servicegroup_id"}}`, encodeURIComponent(String(servicegroupId)))
                .replace(`{${"service_id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (inputEncoding !== undefined) {
                localVarQueryParameter['input_encoding'] = inputEncoding;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (numberPerPage !== undefined) {
                localVarQueryParameter['number_per_page'] = numberPerPage;
            }

            if (sortBy) {
                localVarQueryParameter['sort_by'] = sortBy.join(COLLECTION_FORMATS.csv);
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (drilldown) {
                localVarQueryParameter['drilldown'] = drilldown;
            }

            if (doctype) {
                localVarQueryParameter['doctype'] = doctype.join(COLLECTION_FORMATS.csv);
            }

            if (callback !== undefined) {
                localVarQueryParameter['callback'] = callback;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 指定された条件の全文検索結果を返す。 * 利用するSolrのエンドポイントは「/select」を利用する。 * FacetDrillDownInfo関連して、solr側のドリルダウン用フィールドの値の持ち方は、階層1<tab>階層2とする。 solr4以降の複数フィールドを組み合わせたpivot facet等の新機能があるが、1ページで1ドリルダウンのルートの複数ドリルダウンに対応できないため。 
         * @summary 全文検索
         * @param {string} serviceUniqueName サービスのユニックな名前（エイリアス）
         * @param {string} [q] * 全文検索クエリー文字列
         * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
         * @param {number} [pageNumber] * 取得ページ番号(デフォルト:1)
         * @param {number} [numberPerPage] * 1ページ毎の検索結果表示件数 * 値域: 0～100（デフォルト: 10）
         * @param {Array<string>} [sortBy] * ソート順 * フォーマット   * field1 order,field2 order   * 複数項目指定可能   * orderの設定値     * desc:降順（フィールド指定のみの場合、デフォルト）     * asc:昇順 * 利用方法   * マッチ順（降順） -&gt; score desc（デフォルト）   * 新着順（降順） -&gt; last_modified desc   * タイトル辞書順（昇順） -&gt; title asc   * クリック数順（降順） -&gt; click desc
         * @param {string} [category] 全文検索結果を絞り込むカテゴリ
         * @param {Array<string>} [drilldown] * ドリルダウンにより絞り込み * 複数指定可能。 * 複数指定時には A\\&lt;tab&gt;B のようにtab文字(%09)で区切ること * 複数指定時には A OR BのようにOR検索となる。
         * @param {Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>} [doctype] * 出力されるページのドキュメント種類の指定 * カンマ区切りで複数指定可能 * 複数指定時には、OR検索となる * 設定値   * all（デフォルト）   * html   * pdf   * doc   * xls   * ppt 
         * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByServiceUniqueName: async (serviceUniqueName: string, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', pageNumber?: number, numberPerPage?: number, sortBy?: Array<string>, category?: string, drilldown?: Array<string>, doctype?: Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>, callback?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceUniqueName' is not null or undefined
            assertParamExists('searchByServiceUniqueName', 'serviceUniqueName', serviceUniqueName)
            const localVarPath = `/documents/{service_unique_name}/search`
                .replace(`{${"service_unique_name"}}`, encodeURIComponent(String(serviceUniqueName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (inputEncoding !== undefined) {
                localVarQueryParameter['input_encoding'] = inputEncoding;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (numberPerPage !== undefined) {
                localVarQueryParameter['number_per_page'] = numberPerPage;
            }

            if (sortBy) {
                localVarQueryParameter['sort_by'] = sortBy.join(COLLECTION_FORMATS.csv);
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (drilldown) {
                localVarQueryParameter['drilldown'] = drilldown;
            }

            if (doctype) {
                localVarQueryParameter['doctype'] = doctype.join(COLLECTION_FORMATS.csv);
            }

            if (callback !== undefined) {
                localVarQueryParameter['callback'] = callback;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * * 指定された条件の全文検索結果を返す。 * 利用するSolrのエンドポイントは「/select」を利用する。 * FacetDrillDownInfo関連して、solr側のドリルダウン用フィールドの値の持ち方は、階層1<tab>階層2とする。 solr4以降の複数フィールドを組み合わせたpivot facet等の新機能があるが、1ページで1ドリルダウンのルートの複数ドリルダウンに対応できないため。 
         * @summary 全文検索
         * @param {number} contractId * 契約ID
         * @param {number} servicegroupId * サービスグループid
         * @param {number} serviceId * サービスid
         * @param {string} [q] * 全文検索クエリー文字列
         * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
         * @param {number} [pageNumber] * 取得ページ番号(デフォルト:1)
         * @param {number} [numberPerPage] * 1ページ毎の検索結果表示件数 * 値域: 1～100（デフォルト: 10）
         * @param {Array<string>} [sortBy] * ソート順 * フォーマット   * field1 order,field2 order   * 複数項目指定可能   * orderの設定値     * desc:降順（フィールド指定のみの場合、デフォルト）     * asc:昇順 * 利用方法   * マッチ順（降順） -&gt; score desc（デフォルト）   * 新着順（降順） -&gt; last_modified desc   * タイトル辞書順（昇順） -&gt; title asc   * クリック数順（降順） -&gt; click desc
         * @param {string} [category] 全文検索結果を絞り込むカテゴリ
         * @param {Array<string>} [drilldown] * ドリルダウンにより絞り込み * 複数指定可能。 * 複数指定時には A\\&lt;tab&gt;B のようにtab文字(%09)で区切ること * 複数指定時には A OR BのようにOR検索となる。
         * @param {Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>} [doctype] * 出力されるページのドキュメント種類の指定 * カンマ区切りで複数指定可能 * 複数指定時には、OR検索となる * 設定値   * all（デフォルト）   * html   * pdf   * doc   * xls   * ppt 
         * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(contractId: number, servicegroupId: number, serviceId: number, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', pageNumber?: number, numberPerPage?: number, sortBy?: Array<string>, category?: string, drilldown?: Array<string>, doctype?: Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>, callback?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(contractId, servicegroupId, serviceId, q, inputEncoding, pageNumber, numberPerPage, sortBy, category, drilldown, doctype, callback, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 指定された条件の全文検索結果を返す。 * 利用するSolrのエンドポイントは「/select」を利用する。 * FacetDrillDownInfo関連して、solr側のドリルダウン用フィールドの値の持ち方は、階層1<tab>階層2とする。 solr4以降の複数フィールドを組み合わせたpivot facet等の新機能があるが、1ページで1ドリルダウンのルートの複数ドリルダウンに対応できないため。 
         * @summary 全文検索
         * @param {string} serviceUniqueName サービスのユニックな名前（エイリアス）
         * @param {string} [q] * 全文検索クエリー文字列
         * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
         * @param {number} [pageNumber] * 取得ページ番号(デフォルト:1)
         * @param {number} [numberPerPage] * 1ページ毎の検索結果表示件数 * 値域: 0～100（デフォルト: 10）
         * @param {Array<string>} [sortBy] * ソート順 * フォーマット   * field1 order,field2 order   * 複数項目指定可能   * orderの設定値     * desc:降順（フィールド指定のみの場合、デフォルト）     * asc:昇順 * 利用方法   * マッチ順（降順） -&gt; score desc（デフォルト）   * 新着順（降順） -&gt; last_modified desc   * タイトル辞書順（昇順） -&gt; title asc   * クリック数順（降順） -&gt; click desc
         * @param {string} [category] 全文検索結果を絞り込むカテゴリ
         * @param {Array<string>} [drilldown] * ドリルダウンにより絞り込み * 複数指定可能。 * 複数指定時には A\\&lt;tab&gt;B のようにtab文字(%09)で区切ること * 複数指定時には A OR BのようにOR検索となる。
         * @param {Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>} [doctype] * 出力されるページのドキュメント種類の指定 * カンマ区切りで複数指定可能 * 複数指定時には、OR検索となる * 設定値   * all（デフォルト）   * html   * pdf   * doc   * xls   * ppt 
         * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchByServiceUniqueName(serviceUniqueName: string, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', pageNumber?: number, numberPerPage?: number, sortBy?: Array<string>, category?: string, drilldown?: Array<string>, doctype?: Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>, callback?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchByServiceUniqueName(serviceUniqueName, q, inputEncoding, pageNumber, numberPerPage, sortBy, category, drilldown, doctype, callback, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * * 指定された条件の全文検索結果を返す。 * 利用するSolrのエンドポイントは「/select」を利用する。 * FacetDrillDownInfo関連して、solr側のドリルダウン用フィールドの値の持ち方は、階層1<tab>階層2とする。 solr4以降の複数フィールドを組み合わせたpivot facet等の新機能があるが、1ページで1ドリルダウンのルートの複数ドリルダウンに対応できないため。 
         * @summary 全文検索
         * @param {number} contractId * 契約ID
         * @param {number} servicegroupId * サービスグループid
         * @param {number} serviceId * サービスid
         * @param {string} [q] * 全文検索クエリー文字列
         * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
         * @param {number} [pageNumber] * 取得ページ番号(デフォルト:1)
         * @param {number} [numberPerPage] * 1ページ毎の検索結果表示件数 * 値域: 1～100（デフォルト: 10）
         * @param {Array<string>} [sortBy] * ソート順 * フォーマット   * field1 order,field2 order   * 複数項目指定可能   * orderの設定値     * desc:降順（フィールド指定のみの場合、デフォルト）     * asc:昇順 * 利用方法   * マッチ順（降順） -&gt; score desc（デフォルト）   * 新着順（降順） -&gt; last_modified desc   * タイトル辞書順（昇順） -&gt; title asc   * クリック数順（降順） -&gt; click desc
         * @param {string} [category] 全文検索結果を絞り込むカテゴリ
         * @param {Array<string>} [drilldown] * ドリルダウンにより絞り込み * 複数指定可能。 * 複数指定時には A\\&lt;tab&gt;B のようにtab文字(%09)で区切ること * 複数指定時には A OR BのようにOR検索となる。
         * @param {Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>} [doctype] * 出力されるページのドキュメント種類の指定 * カンマ区切りで複数指定可能 * 複数指定時には、OR検索となる * 設定値   * all（デフォルト）   * html   * pdf   * doc   * xls   * ppt 
         * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(contractId: number, servicegroupId: number, serviceId: number, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', pageNumber?: number, numberPerPage?: number, sortBy?: Array<string>, category?: string, drilldown?: Array<string>, doctype?: Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>, callback?: string, options?: any): AxiosPromise<SearchResponse> {
            return localVarFp.search(contractId, servicegroupId, serviceId, q, inputEncoding, pageNumber, numberPerPage, sortBy, category, drilldown, doctype, callback, options).then((request) => request(axios, basePath));
        },
        /**
         * * 指定された条件の全文検索結果を返す。 * 利用するSolrのエンドポイントは「/select」を利用する。 * FacetDrillDownInfo関連して、solr側のドリルダウン用フィールドの値の持ち方は、階層1<tab>階層2とする。 solr4以降の複数フィールドを組み合わせたpivot facet等の新機能があるが、1ページで1ドリルダウンのルートの複数ドリルダウンに対応できないため。 
         * @summary 全文検索
         * @param {string} serviceUniqueName サービスのユニックな名前（エイリアス）
         * @param {string} [q] * 全文検索クエリー文字列
         * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
         * @param {number} [pageNumber] * 取得ページ番号(デフォルト:1)
         * @param {number} [numberPerPage] * 1ページ毎の検索結果表示件数 * 値域: 0～100（デフォルト: 10）
         * @param {Array<string>} [sortBy] * ソート順 * フォーマット   * field1 order,field2 order   * 複数項目指定可能   * orderの設定値     * desc:降順（フィールド指定のみの場合、デフォルト）     * asc:昇順 * 利用方法   * マッチ順（降順） -&gt; score desc（デフォルト）   * 新着順（降順） -&gt; last_modified desc   * タイトル辞書順（昇順） -&gt; title asc   * クリック数順（降順） -&gt; click desc
         * @param {string} [category] 全文検索結果を絞り込むカテゴリ
         * @param {Array<string>} [drilldown] * ドリルダウンにより絞り込み * 複数指定可能。 * 複数指定時には A\\&lt;tab&gt;B のようにtab文字(%09)で区切ること * 複数指定時には A OR BのようにOR検索となる。
         * @param {Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>} [doctype] * 出力されるページのドキュメント種類の指定 * カンマ区切りで複数指定可能 * 複数指定時には、OR検索となる * 設定値   * all（デフォルト）   * html   * pdf   * doc   * xls   * ppt 
         * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByServiceUniqueName(serviceUniqueName: string, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', pageNumber?: number, numberPerPage?: number, sortBy?: Array<string>, category?: string, drilldown?: Array<string>, doctype?: Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>, callback?: string, options?: any): AxiosPromise<SearchResponse> {
            return localVarFp.searchByServiceUniqueName(serviceUniqueName, q, inputEncoding, pageNumber, numberPerPage, sortBy, category, drilldown, doctype, callback, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * * 指定された条件の全文検索結果を返す。 * 利用するSolrのエンドポイントは「/select」を利用する。 * FacetDrillDownInfo関連して、solr側のドリルダウン用フィールドの値の持ち方は、階層1<tab>階層2とする。 solr4以降の複数フィールドを組み合わせたpivot facet等の新機能があるが、1ページで1ドリルダウンのルートの複数ドリルダウンに対応できないため。 
     * @summary 全文検索
     * @param {number} contractId * 契約ID
     * @param {number} servicegroupId * サービスグループid
     * @param {number} serviceId * サービスid
     * @param {string} [q] * 全文検索クエリー文字列
     * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
     * @param {number} [pageNumber] * 取得ページ番号(デフォルト:1)
     * @param {number} [numberPerPage] * 1ページ毎の検索結果表示件数 * 値域: 1～100（デフォルト: 10）
     * @param {Array<string>} [sortBy] * ソート順 * フォーマット   * field1 order,field2 order   * 複数項目指定可能   * orderの設定値     * desc:降順（フィールド指定のみの場合、デフォルト）     * asc:昇順 * 利用方法   * マッチ順（降順） -&gt; score desc（デフォルト）   * 新着順（降順） -&gt; last_modified desc   * タイトル辞書順（昇順） -&gt; title asc   * クリック数順（降順） -&gt; click desc
     * @param {string} [category] 全文検索結果を絞り込むカテゴリ
     * @param {Array<string>} [drilldown] * ドリルダウンにより絞り込み * 複数指定可能。 * 複数指定時には A\\&lt;tab&gt;B のようにtab文字(%09)で区切ること * 複数指定時には A OR BのようにOR検索となる。
     * @param {Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>} [doctype] * 出力されるページのドキュメント種類の指定 * カンマ区切りで複数指定可能 * 複数指定時には、OR検索となる * 設定値   * all（デフォルト）   * html   * pdf   * doc   * xls   * ppt 
     * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public search(contractId: number, servicegroupId: number, serviceId: number, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', pageNumber?: number, numberPerPage?: number, sortBy?: Array<string>, category?: string, drilldown?: Array<string>, doctype?: Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>, callback?: string, options?: any) {
        return SearchApiFp(this.configuration).search(contractId, servicegroupId, serviceId, q, inputEncoding, pageNumber, numberPerPage, sortBy, category, drilldown, doctype, callback, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 指定された条件の全文検索結果を返す。 * 利用するSolrのエンドポイントは「/select」を利用する。 * FacetDrillDownInfo関連して、solr側のドリルダウン用フィールドの値の持ち方は、階層1<tab>階層2とする。 solr4以降の複数フィールドを組み合わせたpivot facet等の新機能があるが、1ページで1ドリルダウンのルートの複数ドリルダウンに対応できないため。 
     * @summary 全文検索
     * @param {string} serviceUniqueName サービスのユニックな名前（エイリアス）
     * @param {string} [q] * 全文検索クエリー文字列
     * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
     * @param {number} [pageNumber] * 取得ページ番号(デフォルト:1)
     * @param {number} [numberPerPage] * 1ページ毎の検索結果表示件数 * 値域: 0～100（デフォルト: 10）
     * @param {Array<string>} [sortBy] * ソート順 * フォーマット   * field1 order,field2 order   * 複数項目指定可能   * orderの設定値     * desc:降順（フィールド指定のみの場合、デフォルト）     * asc:昇順 * 利用方法   * マッチ順（降順） -&gt; score desc（デフォルト）   * 新着順（降順） -&gt; last_modified desc   * タイトル辞書順（昇順） -&gt; title asc   * クリック数順（降順） -&gt; click desc
     * @param {string} [category] 全文検索結果を絞り込むカテゴリ
     * @param {Array<string>} [drilldown] * ドリルダウンにより絞り込み * 複数指定可能。 * 複数指定時には A\\&lt;tab&gt;B のようにtab文字(%09)で区切ること * 複数指定時には A OR BのようにOR検索となる。
     * @param {Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>} [doctype] * 出力されるページのドキュメント種類の指定 * カンマ区切りで複数指定可能 * 複数指定時には、OR検索となる * 設定値   * all（デフォルト）   * html   * pdf   * doc   * xls   * ppt 
     * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchByServiceUniqueName(serviceUniqueName: string, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', pageNumber?: number, numberPerPage?: number, sortBy?: Array<string>, category?: string, drilldown?: Array<string>, doctype?: Array<'all' | 'html' | 'pdf' | 'doc' | 'xls' | 'ppt'>, callback?: string, options?: any) {
        return SearchApiFp(this.configuration).searchByServiceUniqueName(serviceUniqueName, q, inputEncoding, pageNumber, numberPerPage, sortBy, category, drilldown, doctype, callback, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuggestionApi - axios parameter creator
 * @export
 */
export const SuggestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * クエリに対してプレフィックスで始まる関連キーワード一覧を取得する。
         * @summary 関連キーワード
         * @param {number} contractId 
         * @param {number} servicegroupId 
         * @param {number} serviceId 
         * @param {string} [q] 全文検索クエリ文字列
         * @param {string} [prefix] 関連キーワードのプレフィックス文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedKeywords: async (contractId: number, servicegroupId: number, serviceId: number, q?: string, prefix?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getRelatedKeywords', 'contractId', contractId)
            // verify required parameter 'servicegroupId' is not null or undefined
            assertParamExists('getRelatedKeywords', 'servicegroupId', servicegroupId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('getRelatedKeywords', 'serviceId', serviceId)
            const localVarPath = `/documents/{contract_id}/{servicegroup_id}/{service_id}/related_keywords`
                .replace(`{${"contract_id"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"servicegroup_id"}}`, encodeURIComponent(String(servicegroupId)))
                .replace(`{${"service_id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (prefix !== undefined) {
                localVarQueryParameter['prefix'] = prefix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クエリに対してプレフィックスで始まる関連キーワード一覧を取得する。
         * @summary 関連キーワード
         * @param {string} serviceUniqueName サービスのユニックな名前（エイリアス）
         * @param {string} [q] 全文検索クエリ文字列
         * @param {string} [prefix] 関連キーワードのプレフィックス文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedKeywordsByUniqueName: async (serviceUniqueName: string, q?: string, prefix?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceUniqueName' is not null or undefined
            assertParamExists('getRelatedKeywordsByUniqueName', 'serviceUniqueName', serviceUniqueName)
            const localVarPath = `/documents/{service_unique_name}/related_keywords`
                .replace(`{${"service_unique_name"}}`, encodeURIComponent(String(serviceUniqueName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (prefix !== undefined) {
                localVarQueryParameter['prefix'] = prefix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 指定された条件のサジェスト結果を返す。 * 利用するSolrのエンドポイントは「/suggest」を利用する。 
         * @summary サジェスト
         * @param {number} contractId * 契約id
         * @param {number} servicegroupId * サービスグループid
         * @param {number} serviceId * サービスid
         * @param {string} [q] * 全文検索クエリー文字列
         * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
         * @param {number} [numberPerSuggestion] * サジェストの結果表示件数 * デフォルト：10
         * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggest: async (contractId: number, servicegroupId: number, serviceId: number, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', numberPerSuggestion?: number, callback?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('suggest', 'contractId', contractId)
            // verify required parameter 'servicegroupId' is not null or undefined
            assertParamExists('suggest', 'servicegroupId', servicegroupId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('suggest', 'serviceId', serviceId)
            const localVarPath = `/documents/{contract_id}/{servicegroup_id}/{service_id}/suggest`
                .replace(`{${"contract_id"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"servicegroup_id"}}`, encodeURIComponent(String(servicegroupId)))
                .replace(`{${"service_id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (inputEncoding !== undefined) {
                localVarQueryParameter['input_encoding'] = inputEncoding;
            }

            if (numberPerSuggestion !== undefined) {
                localVarQueryParameter['number_per_suggestion'] = numberPerSuggestion;
            }

            if (callback !== undefined) {
                localVarQueryParameter['callback'] = callback;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 指定された条件のサジェスト結果を返す。 * 利用するSolrのエンドポイントは「/suggest」を利用する。 
         * @summary サジェスト
         * @param {string} serviceUniqueName サービスのユニックな名前（エイリアス）
         * @param {string} [q] * 全文検索クエリー文字列
         * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
         * @param {number} [numberPerSuggestion] * サジェストの結果表示件数 * デフォルト：10
         * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestByUniqueName: async (serviceUniqueName: string, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', numberPerSuggestion?: number, callback?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceUniqueName' is not null or undefined
            assertParamExists('suggestByUniqueName', 'serviceUniqueName', serviceUniqueName)
            const localVarPath = `/documents/{service_unique_name}/suggest`
                .replace(`{${"service_unique_name"}}`, encodeURIComponent(String(serviceUniqueName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (inputEncoding !== undefined) {
                localVarQueryParameter['input_encoding'] = inputEncoding;
            }

            if (numberPerSuggestion !== undefined) {
                localVarQueryParameter['number_per_suggestion'] = numberPerSuggestion;
            }

            if (callback !== undefined) {
                localVarQueryParameter['callback'] = callback;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuggestionApi - functional programming interface
 * @export
 */
export const SuggestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuggestionApiAxiosParamCreator(configuration)
    return {
        /**
         * クエリに対してプレフィックスで始まる関連キーワード一覧を取得する。
         * @summary 関連キーワード
         * @param {number} contractId 
         * @param {number} servicegroupId 
         * @param {number} serviceId 
         * @param {string} [q] 全文検索クエリ文字列
         * @param {string} [prefix] 関連キーワードのプレフィックス文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatedKeywords(contractId: number, servicegroupId: number, serviceId: number, q?: string, prefix?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelatedKeywordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatedKeywords(contractId, servicegroupId, serviceId, q, prefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クエリに対してプレフィックスで始まる関連キーワード一覧を取得する。
         * @summary 関連キーワード
         * @param {string} serviceUniqueName サービスのユニックな名前（エイリアス）
         * @param {string} [q] 全文検索クエリ文字列
         * @param {string} [prefix] 関連キーワードのプレフィックス文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatedKeywordsByUniqueName(serviceUniqueName: string, q?: string, prefix?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelatedKeywordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatedKeywordsByUniqueName(serviceUniqueName, q, prefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 指定された条件のサジェスト結果を返す。 * 利用するSolrのエンドポイントは「/suggest」を利用する。 
         * @summary サジェスト
         * @param {number} contractId * 契約id
         * @param {number} servicegroupId * サービスグループid
         * @param {number} serviceId * サービスid
         * @param {string} [q] * 全文検索クエリー文字列
         * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
         * @param {number} [numberPerSuggestion] * サジェストの結果表示件数 * デフォルト：10
         * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggest(contractId: number, servicegroupId: number, serviceId: number, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', numberPerSuggestion?: number, callback?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suggest(contractId, servicegroupId, serviceId, q, inputEncoding, numberPerSuggestion, callback, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 指定された条件のサジェスト結果を返す。 * 利用するSolrのエンドポイントは「/suggest」を利用する。 
         * @summary サジェスト
         * @param {string} serviceUniqueName サービスのユニックな名前（エイリアス）
         * @param {string} [q] * 全文検索クエリー文字列
         * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
         * @param {number} [numberPerSuggestion] * サジェストの結果表示件数 * デフォルト：10
         * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestByUniqueName(serviceUniqueName: string, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', numberPerSuggestion?: number, callback?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suggestByUniqueName(serviceUniqueName, q, inputEncoding, numberPerSuggestion, callback, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuggestionApi - factory interface
 * @export
 */
export const SuggestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuggestionApiFp(configuration)
    return {
        /**
         * クエリに対してプレフィックスで始まる関連キーワード一覧を取得する。
         * @summary 関連キーワード
         * @param {number} contractId 
         * @param {number} servicegroupId 
         * @param {number} serviceId 
         * @param {string} [q] 全文検索クエリ文字列
         * @param {string} [prefix] 関連キーワードのプレフィックス文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedKeywords(contractId: number, servicegroupId: number, serviceId: number, q?: string, prefix?: string, options?: any): AxiosPromise<GetRelatedKeywordsResponse> {
            return localVarFp.getRelatedKeywords(contractId, servicegroupId, serviceId, q, prefix, options).then((request) => request(axios, basePath));
        },
        /**
         * クエリに対してプレフィックスで始まる関連キーワード一覧を取得する。
         * @summary 関連キーワード
         * @param {string} serviceUniqueName サービスのユニックな名前（エイリアス）
         * @param {string} [q] 全文検索クエリ文字列
         * @param {string} [prefix] 関連キーワードのプレフィックス文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedKeywordsByUniqueName(serviceUniqueName: string, q?: string, prefix?: string, options?: any): AxiosPromise<GetRelatedKeywordsResponse> {
            return localVarFp.getRelatedKeywordsByUniqueName(serviceUniqueName, q, prefix, options).then((request) => request(axios, basePath));
        },
        /**
         * * 指定された条件のサジェスト結果を返す。 * 利用するSolrのエンドポイントは「/suggest」を利用する。 
         * @summary サジェスト
         * @param {number} contractId * 契約id
         * @param {number} servicegroupId * サービスグループid
         * @param {number} serviceId * サービスid
         * @param {string} [q] * 全文検索クエリー文字列
         * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
         * @param {number} [numberPerSuggestion] * サジェストの結果表示件数 * デフォルト：10
         * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggest(contractId: number, servicegroupId: number, serviceId: number, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', numberPerSuggestion?: number, callback?: string, options?: any): AxiosPromise<SuggestResponse> {
            return localVarFp.suggest(contractId, servicegroupId, serviceId, q, inputEncoding, numberPerSuggestion, callback, options).then((request) => request(axios, basePath));
        },
        /**
         * * 指定された条件のサジェスト結果を返す。 * 利用するSolrのエンドポイントは「/suggest」を利用する。 
         * @summary サジェスト
         * @param {string} serviceUniqueName サービスのユニックな名前（エイリアス）
         * @param {string} [q] * 全文検索クエリー文字列
         * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
         * @param {number} [numberPerSuggestion] * サジェストの結果表示件数 * デフォルト：10
         * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestByUniqueName(serviceUniqueName: string, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', numberPerSuggestion?: number, callback?: string, options?: any): AxiosPromise<SuggestResponse> {
            return localVarFp.suggestByUniqueName(serviceUniqueName, q, inputEncoding, numberPerSuggestion, callback, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuggestionApi - object-oriented interface
 * @export
 * @class SuggestionApi
 * @extends {BaseAPI}
 */
export class SuggestionApi extends BaseAPI {
    /**
     * クエリに対してプレフィックスで始まる関連キーワード一覧を取得する。
     * @summary 関連キーワード
     * @param {number} contractId 
     * @param {number} servicegroupId 
     * @param {number} serviceId 
     * @param {string} [q] 全文検索クエリ文字列
     * @param {string} [prefix] 関連キーワードのプレフィックス文字列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuggestionApi
     */
    public getRelatedKeywords(contractId: number, servicegroupId: number, serviceId: number, q?: string, prefix?: string, options?: any) {
        return SuggestionApiFp(this.configuration).getRelatedKeywords(contractId, servicegroupId, serviceId, q, prefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クエリに対してプレフィックスで始まる関連キーワード一覧を取得する。
     * @summary 関連キーワード
     * @param {string} serviceUniqueName サービスのユニックな名前（エイリアス）
     * @param {string} [q] 全文検索クエリ文字列
     * @param {string} [prefix] 関連キーワードのプレフィックス文字列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuggestionApi
     */
    public getRelatedKeywordsByUniqueName(serviceUniqueName: string, q?: string, prefix?: string, options?: any) {
        return SuggestionApiFp(this.configuration).getRelatedKeywordsByUniqueName(serviceUniqueName, q, prefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 指定された条件のサジェスト結果を返す。 * 利用するSolrのエンドポイントは「/suggest」を利用する。 
     * @summary サジェスト
     * @param {number} contractId * 契約id
     * @param {number} servicegroupId * サービスグループid
     * @param {number} serviceId * サービスid
     * @param {string} [q] * 全文検索クエリー文字列
     * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
     * @param {number} [numberPerSuggestion] * サジェストの結果表示件数 * デフォルト：10
     * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuggestionApi
     */
    public suggest(contractId: number, servicegroupId: number, serviceId: number, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', numberPerSuggestion?: number, callback?: string, options?: any) {
        return SuggestionApiFp(this.configuration).suggest(contractId, servicegroupId, serviceId, q, inputEncoding, numberPerSuggestion, callback, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 指定された条件のサジェスト結果を返す。 * 利用するSolrのエンドポイントは「/suggest」を利用する。 
     * @summary サジェスト
     * @param {string} serviceUniqueName サービスのユニックな名前（エイリアス）
     * @param {string} [q] * 全文検索クエリー文字列
     * @param {'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1'} [inputEncoding] * 入力エンコーディング * 設定値（以下何か）   * utf8（デフォルト）   * sjis   * jis   * euc-jp   * euc-kr   * iso-8859-1 
     * @param {number} [numberPerSuggestion] * サジェストの結果表示件数 * デフォルト：10
     * @param {string} [callback] * JSONP用のコールバック関数 * 指定ない場合は、JSONにて返却
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuggestionApi
     */
    public suggestByUniqueName(serviceUniqueName: string, q?: string, inputEncoding?: 'utf8' | 'sjis' | 'jis' | 'euc-jp' | 'euc-kr' | 'iso-8859-1', numberPerSuggestion?: number, callback?: string, options?: any) {
        return SuggestionApiFp(this.configuration).suggestByUniqueName(serviceUniqueName, q, inputEncoding, numberPerSuggestion, callback, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ヘルスチェック処理実施。
         * @summary ヘルスチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthCheck: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/health_check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * ヘルスチェック処理実施。
         * @summary ヘルスチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthCheck(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHealthCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * ヘルスチェック処理実施。
         * @summary ヘルスチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthCheck(options?: any): AxiosPromise<GetHealthCheckResponse> {
            return localVarFp.getHealthCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * ヘルスチェック処理実施。
     * @summary ヘルスチェック
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getHealthCheck(options?: any) {
        return SystemApiFp(this.configuration).getHealthCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


